import { FileUpload } from '@findep/mf-landings-core'
import { Typography, Box } from '@material-ui/core';
import { css } from '@emotion/react'

const text = css`        
        padding: 1rem;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
`;

const textSuccessCss = css`        
        padding: 1rem;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.15px;
        color: #008f39;
`;

const styleTitle = css`
    margin-bottom: 1rem;
    margin-top: 1rem;    
`

const CardFileUploadComponent = (props) => {             
    return (        
        <Box css={styleTitle}>
            <Typography css={text}>                        
                {props.message}
            </Typography>      
            <FileUpload
                id={`comprobante-ingresos-cargararchivo-input-${props.id}`}
                accept=".png, .jpg, .jpeg, .pdf"                        
                help='Presiona el botón cargar un archivo (puedes sacar una foto en un dispositivo móvil)'
                label='Cargar archivo'
                fileName={props.fileName}
                helperText='Puedes subir archivos .jpg, .png, o .pdf'                          
                onChange={e => props.onChange(e, "File", props.id)}
                required  
                disabled={props.active}                          
            />            
        </Box>
    )       
}



export default CardFileUploadComponent;